import { Link } from "react-router-dom";
import "./bottom.css";
import yos from "./Images/Yos.png";
import noc from "./Images/noc.jpg";
import eqp from "./Images/eqp.png";
import countries from "./Images/countries.png";
import logo from "./Images/logo.png";
import lt from "./Images/01.png";
import rt from "./Images/02.png";

function bottom() {
  const scrollToUpper = () => {
    let sectionTop;
    if (window.innerWidth <= 768) {
      sectionTop = window.innerHeight * 0.1;
    } else {
      sectionTop = window.innerHeight * 1;
    }
    window.scrollTo({
      top: sectionTop,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div className="counterCoverSafe">
        <div className="counterCover">
          <div className="counts">
            <img src={noc} alt="number if clients" />
            <p>5000+</p>
            <p>Clients</p>
          </div>
          <div className="counts1">
            <img src={eqp} alt="sold equipments" />
            <p>50000+</p>
            <p>Installed Equipments</p>
          </div>
          <div className="counts2">
            <img src={countries} alt="countries serving" />
            <p>30+</p>
            <p>Countries Serving</p>
          </div>
          <div className="counts3">
            <img src={yos} alt="years of service" />
            <p>2 Decades</p>
            <p>of Service</p>
          </div>
        </div>
      </div>
      <div className="flex_container">
        <div className="logoCol">
          <img src={logo} />
          <div className="aboutImg">
            <img src={lt} />
            <img src={rt} />
          </div>
        </div>
        <div className="cu">
          <h3>Contact Us</h3>
          <div className="cu_info">
            <p>
              <span>Eminence Equipments Pvt. Limited</span>
              <br /> Plot no. C-22/1,
              <br /> M.I.D.C. Chakan phase II,
              <br /> New Industrial area,
              <br /> Village - Bhamboli, Tal -Khed,
              <br /> Dist Pune 410 501
            </p>
          </div>

          <br />
          <div className="phone">
            <h3>Phone</h3>
            <span>&#9742;</span> +91 02135 619 400
          </div>
          <div className="email">
            <h3>Email</h3>
            <span>📧</span> marketing@eminenceequipments.com
          </div>
        </div>
        {/* <hr /> */}
        <div className="ql">
          <h3>Quick Links</h3>
          <div className="menuCover">
            <div className="menuLinks">
              <Link to="/">Home</Link>
              <Link to="/AboutUs">About Us</Link>
              <Link onClick={scrollToUpper}>Products</Link>
              <Link to="/">Career</Link>
              <Link to="/CSR">CSR</Link>
              <Link to="/">Contact Us</Link>
            </div>
            {/* <div className="menuLinks1">
              <Link to="/BE">Bucket Elevator</Link>
              <Link to="/SC">Screw Conveyor</Link>
              <Link to="/CC">Chain Conveyor</Link>
              <Link to="/BC">Belt Conveyor</Link>
              <Link to="/Slidegate">Slide Gate</Link>
              <Link to="/Divertor">Divertor</Link>
              <Link to="/TruckLoader">Truck Loader</Link>
              <Link to="/BagStacker">Bag Stacker</Link>
              <Link to="/PortableBeltConveyor">Portable Belt Conveyor</Link>
              <Link to="/ZtypeBucketConveyor">Z Type Bucket Conveyor</Link>
              <Link to="/TurnHead">Turn Head</Link>
            </div> */}
          </div>
        </div>
        {/* <hr /> */}
        <div className="map">
          <h3>Locate Us</h3>
          <a
            href="https://www.google.com/maps/place/Eminence+Equipments+Private+Limited/@18.7977633,73.7847917,17z/data=!3m1!4b1!4m6!3m5!1s0x3bc2b94d65555555:0x4649437bfc65ffad!8m2!3d18.7977633!4d73.7847917!16s%2Fg%2F1tg2byvq?hl=en&entry=ttu"
            target="_blank"
            rel="noopener"
          >
            <img alt="map" src="./Assets/map.jpg" />
          </a>
        </div>
      </div>
    </>
  );
}

export default bottom;
