import "./OurClients.css";
import l001 from "../Images/L001.jpg";
import l002 from "../Images/L002.jpg";
import l003 from "../Images/L003.jpg";
import l004 from "../Images/L004.jpg";
import l005 from "../Images/L005.jpg";
import l006 from "../Images/L006.jpg";
import l007 from "../Images/L007.jpg";
import l008 from "../Images/L008.jpg";
import l009 from "../Images/L009.jpg";
import l010 from "../Images/L010.jpg";
import l011 from "../Images/L011.jpg";
import l012 from "../Images/L012.jpg";
import l013 from "../Images/L013.jpg";
import l014 from "../Images/L014.jpg";
import l015 from "../Images/L015.jpg";
import l016 from "../Images/L016.jpg";
import l017 from "../Images/L017.jpg";

// import ImageSlider, { Slide } from "react-auto-image-slider";
import React, { useEffect } from "react";

function OurClients() {
  useEffect(() => {
    document.title = "Our Clients";
  }, []);
  return (
    <>
      <div className="logo-slider">
        <div className="clientHeader">
          <h2>Our Clients</h2>
        </div>
        <div className="logo-slide-track">
          <div className="slide">
            <img src={l001} alt="" />
          </div>
          <div className="slide">
            <img src={l002} alt="" />
          </div>
          <div className="slide">
            <img src={l003} alt="" />
          </div>
          <div className="slide">
            <img src={l004} alt="" />
          </div>
          <div className="slide">
            <img src={l005} alt="" />
          </div>
          <div className="slide">
            <img src={l006} alt="" />
          </div>
          <div className="slide">
            <img src={l007} alt="" />
          </div>
          <div className="slide">
            <img src={l008} alt="" />
          </div>
          <div className="slide">
            <img src={l009} alt="" />
          </div>
          <div className="slide">
            <img src={l010} alt="" />
          </div>
          <div className="slide">
            <img src={l011} alt="" />
          </div>
          <div className="slide">
            <img src={l012} alt="" />
          </div>
          <div className="slide">
            <img src={l013} alt="" />
          </div>
          <div className="slide">
            <img src={l014} alt="" />
          </div>
          <div className="slide">
            <img src={l015} alt="" />
          </div>
          <div className="slide">
            <img src={l016} alt="" />
          </div>
          <div className="slide">
            <img src={l017} alt="" />
          </div>
        </div>
      </div>
    </>
  );
}

export default OurClients;
