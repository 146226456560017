// import logo from "./logo.svg";
import "./App.css";
import Nav from "./components/Nav";
import Sectors from "./components/Sectors";
import Manufacturing from "./components/Manufacturing";
import Products from "./components/Products";
import ImageSlider, { Slide } from "react-auto-image-slider";
import Bottom from "./components/bottom";
// import Bottom from "./components/Bottom";
import c1 from "./components/Images/c1.jpg";
import Clients from "./components/Clients/OurClients";
import React, { useEffect } from "react";

function Home() {
  useEffect(() => {
    document.title = "Landing Page";
  }, []);
  return (
    <>
      <div className="upperNav">
        <Nav></Nav>
      </div>
      <div className="carousel_landing">
        {/* <div className="space"></div> */}
        <ImageSlider effectDelay={500} autoPlayDelay={2000}>
          <Slide>
            <img src={c1} alt="" />
          </Slide>
          <Slide>
            <img src="Assets/c2.jpg" alt="" />
          </Slide>
          <Slide>
            <img src="Assets/c3.jpg" alt="" />
          </Slide>
        </ImageSlider>
      </div>
      <div className="space"></div>
      <div className="pr">
        <Products></Products>
      </div>
      <div className="space"></div>
      <Sectors></Sectors>
      <div className="space"></div>
      <Manufacturing></Manufacturing>
      {/* <div className="enquire">
        <h2>ENQUIRE NOW</h2>
      </div> */}
      <div className="clientSection">
        <Clients></Clients>
      </div>
      {/* <div className="space1"></div> */}
      <Bottom></Bottom>
    </>
  );
}

export default Home;
