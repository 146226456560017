import React, { Suspense } from "react";
import "./App.css";
import bottom from "./Home";
import { HashRouter as Router, Routes, Route } from "react-router-dom";

const Home = React.lazy(() => import("./Home"));
const SC = React.lazy(() => import("./components/SC/SC"));
const BC = React.lazy(() => import("./components/BC/BC"));
const CC = React.lazy(() => import("./components/CC/CC"));
const BE = React.lazy(() => import("./components/BE/BE"));
const Slidegate = React.lazy(() => import("./components/Slidegate/SlideGate"));
const Divertor = React.lazy(() => import("./components/Divertor/Divertor"));
const TruckLoader = React.lazy(() =>
  import("./components/TruckLoader/TruckLoader")
);
const BagStacker = React.lazy(() =>
  import("./components/BagStacker/BagStacker")
);
const PortableBeltConveyor = React.lazy(() =>
  import("./components/PortableBeltConveyor/PortableBeltConveyor")
);
const ZtypeBucketConveyor = React.lazy(() =>
  import("./components/ZtypeBucketConveyor/ZtypeBucketConveyor")
);
const TurnHead = React.lazy(() => import("./components/TurnHead/TurnHead"));
const About = React.lazy(() => import("./components/AboutUs"));
const Next = React.lazy(() => import("./components/SC/secondPage"));
const NextCC = React.lazy(() => import("./components/CC/CCsecond"));
const NextBE = React.lazy(() => import("./components/BE/BEsecond"));
const NextBC = React.lazy(() => import("./components/BC/BCsecond"));
const CSR = React.lazy(() => import("./components/CSR"));
const OurClients = React.lazy(() => import("./components/Clients/OurClients"));

function App() {
  return (
    <>
      <Suspense fallback={<p>Loading...</p>}>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/SC" element={<SC />} />
            <Route path="/CC" element={<CC />} />
            <Route path="/BC" element={<BC />} />
            <Route path="/BE" element={<BE />} />
            <Route path="/Slidegate" element={<Slidegate />} />
            <Route path="/Divertor" element={<Divertor />} />
            <Route path="/TruckLoader" element={<TruckLoader />} />
            <Route path="/BagStacker" element={<BagStacker />} />
            <Route
              path="/PortableBeltConveyor"
              element={<PortableBeltConveyor />}
            />
            <Route
              path="/ZtypeBucketConveyor"
              element={<ZtypeBucketConveyor />}
            />
            <Route path="/TurnHead" element={<TurnHead />} />
            <Route path="/AboutUs" element={<About />} />
            <Route path="/next" element={<Next />} />
            <Route path="/nextCC" element={<NextCC />} />
            <Route path="/nextBE" element={<NextBE />} />
            <Route path="/nextBC" element={<NextBC />} />
            <Route path="/CSR" element={<CSR />} />
            <Route path="/OurClients" element={<OurClients />} />
            <Route path="/ContactUS" Component={bottom} />
          </Routes>
        </Router>
      </Suspense>
    </>
  );
}

export default App;
