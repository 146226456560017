import "./box_content.css";
import React from "react";
// import { Link } from "react-router-dom";
// import Carousel from "react-grid-carousel";

function Sectors() {
  return (
    <>
      <div className="sectorSection">
        <div className="topic_s">
          <h2>SECTORS</h2>
        </div>
        <div className="container_s">
          <div className="row">
            <div className="column_agri">
              <div className="card__face card__face--front">
                <img
                  src="Assets/sc001.jpg"
                  alt=""
                  // style="width:400px; height:300px"
                />
                <div className="name">RICE MILL</div>
                {/* <div className="text">
                <p>1.FIRST NAME</p>
                <p>2.SECOND NAME</p>
                <p>3.THIRD NAME</p>
                <p>4.FOURTH NAME</p>
              </div> */}
              </div>
            </div>
            <div className="column">
              <div className="card__face card__face--front">
                <img
                  src="Assets/sc002.jpg"
                  alt=""
                  // style="width:400px; height:300px"
                />
                <div className="name">FLOUR MILL</div>
              </div>
            </div>
            <div className="column">
              <div className="card__face card__face--front">
                <img
                  src="Assets/sc003.jpg"
                  alt=""
                  // style="width:400px; height:300px"
                />
                <div className="name">DAL/PULSES MILL</div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="column">
              <div className="card__face card__face--front">
                <img
                  src="Assets/sc004.jpg"
                  alt=""
                  // style="width:400px; height:300px"
                />
                <div className="name">OIL EXTRACTION</div>
              </div>
            </div>
            <div className="column">
              <div className="card__face card__face--front">
                <img
                  src="Assets/sc005.jpg"
                  alt=""
                  // style="width:400px; height:300px"
                />
                <div className="name">FEED MILL</div>
              </div>
            </div>
            <div className="column">
              <div className="card__face card__face--front">
                <img
                  src="Assets/sc006.jpg"
                  alt=""
                  // style="width:400px; height:300px"
                />
                <div className="name">GRAIN STORAGE SILO HANDLING</div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="column">
              <div className="card__face card__face--front">
                <img
                  src="Assets/sc007.jpg"
                  alt=""
                  // style="width:400px; height:300px"
                />
                <div className="name">FOOD PROCESSING AND PACKAGING</div>
              </div>
            </div>
            <div className="column">
              <div className="card__face card__face--front">
                <img
                  src="Assets/sc008.jpg"
                  alt=""
                  // style="width:400px; height:300px"
                />
                <div className="name">BREWERIES</div>
              </div>
            </div>
            <div className="column">
              <div className="card__face card__face--front">
                {/* <img
                src="Assets/11166.jpg"
                alt=""
                // style="width:400px; height:300px"
              /> */}
                <img
                  src="Assets/sc009.jpg"
                  alt=""
                  // style="width:400px; height:300px"
                />
                <div className="name">COAL AND ASH HANDLING</div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="column">
              <div className="card__face card__face--front">
                <img
                  src="Assets/sc010.jpg"
                  alt=""
                  // style="width:400px; height:300px"
                />
                <div className="name">ETHANOL PLANT</div>
              </div>
            </div>
            <div className="column">
              <div className="card__face card__face--front">
                <img
                  src="Assets/addon.jpg"
                  alt=""
                  // style="width:400px; height:300px"
                />
                <div className="name">PORT Handling</div>
              </div>
            </div>
            <div className="column">
              <div className="card__face card__face--front">
                <img
                  src="Assets/sc011.jpg"
                  alt=""
                  // style="width:400px; height:300px"
                />
                <div className="name">TANKS AND STRUCTURE</div>
              </div>
            </div>
          </div>
        </div>
        <div className="indication">
          <p>&#10229; SLIDE TO VIEW MORE &#10230;</p>
        </div>
      </div>
    </>
  );
}

export default Sectors;
