import "./box_content.css";

function Manufacturing() {
  return (
    <>
      <div className="manufacturingSection">
        <div className="topic_ms">
          <h2>MANUFACTURING SETUP</h2>
        </div>
        <div className="container_ms">
          <div className="row">
            <div className="column">
              <div className="card__face card__face--front">
                <img
                  src="Assets/mnf001.jpg"
                  alt=""
                  // style="width:400px; height:300px"
                />
                <div className="name">3Kw Trumpf Laser</div>
              </div>
            </div>
            <div className="column">
              <div className="card__face card__face--front">
                <img
                  src="Assets/mnf002.jpg"
                  alt=""
                  // style="width:400px; height:300px"
                />
                <div className="name">12 Kw Laser</div>
              </div>
            </div>
            <div className="column">
              <div className="card__face card__face--front">
                <img
                  src="Assets/mnf003.jpg"
                  alt=""
                  // style="width:400px; height:300px"
                />
                <div className="name">6 Kw Laser</div>
              </div>
            </div>
            <div className="column">
              <div className="card__face card__face--front">
                <img
                  src="Assets/mnf004.jpg"
                  alt=""
                  // style="width:400px; height:300px"
                />
                <div className="name">Pipe Cutting Laser</div>
              </div>
            </div>
          </div>
        </div>
        <div className="indication">
          <p>&#10229; SLIDE TO VIEW MORE &#10230;</p>
        </div>
      </div>
    </>
  );
}

export default Manufacturing;
