import "./box_content.css";
import React from "react";
import { Link } from "react-router-dom";
import Carousel from "react-grid-carousel";

function Products() {
  return (
    <>
      <div className="prodSection">
        <div className="topic_p">
          <h2>PRODUCTS</h2>
        </div>
        <div className="carousel">
          {/* <h1>To add soon!</h1> */}
          <Carousel cols={3} rows={2} gap={10} loop>
            <Carousel.Item>
              <Link to="/BE">
                <img
                  className="prodImg"
                  alt=""
                  width="50%"
                  src="Assets/p001.jpg"
                />
              </Link>
              <div className="below_img">
                <p>Bucket Elevator</p>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <Link to="/CC">
                <img
                  className="prodImg"
                  alt=""
                  width="30%"
                  src="Assets/p002.jpg"
                />
              </Link>
              <div className="below_img">
                <p>Chain Conveyor</p>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <Link to="BC">
                <img
                  className="prodImg"
                  alt=""
                  width="30%"
                  src="Assets/p003.jpg"
                />
              </Link>
              <div className="below_img">
                <p>Belt Conveyor</p>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <Link to="/SC">
                <img
                  className="prodImg"
                  alt=""
                  width="30%"
                  src="Assets/p004.jpg"
                />
              </Link>
              <div className="below_img">
                <p>Screw Conveyor</p>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <Link to="/Slidegate">
                <img
                  className="prodImg"
                  alt=""
                  width="30%"
                  src="Assets/p005.jpg"
                />
              </Link>
              <div className="below_img">
                <p>Slide Gate</p>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <Link to="/Divertor">
                <img
                  className="prodImg"
                  alt=""
                  width="30%"
                  src="Assets/p006.jpg"
                />
              </Link>
              <div className="below_img">
                <p>Divertor</p>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <Link to="TruckLoader">
                <img
                  className="prodImg"
                  alt=""
                  width="30%"
                  src="Assets/p007.jpg"
                />
              </Link>
              <div className="below_img">
                <p>Truck Loader</p>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <Link to="BagStacker">
                <img
                  className="prodImg"
                  alt=""
                  width="30%"
                  src="Assets/p008.jpg"
                />
              </Link>
              <div className="below_img">
                <p>Bag Stacker</p>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <Link to="/PortableBeltConveyor">
                <img
                  className="prodImg"
                  alt=""
                  width="30%"
                  src="Assets/p009.jpg"
                />
              </Link>
              <div className="below_img">
                <p>Portable Belt Conveyor</p>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <Link to="/ZTypeBucketConveyor">
                <img
                  className="prodImg"
                  alt=""
                  width="30%"
                  src="Assets/p010.jpeg"
                />
              </Link>
              <div className="below_img">
                <p>Z Conveyor Multi Discharge</p>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <Link to="/TurnHead">
                <img
                  className="prodImg"
                  alt=""
                  width="30%"
                  src="Assets/p011.jpg"
                />
              </Link>
              <div className="below_img">
                <p>Turn Head</p>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <Link to="/ZTypeBucketConveyor">
                <img
                  className="prodImg"
                  alt=""
                  width="30%"
                  src="Assets/p012.jpeg"
                />
              </Link>
              <div className="below_img">
                <p>Z Conveyor Single Discharge</p>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
        <div className="indication">
          <p>&#10229; SLIDE TO VIEW MORE &#10230;</p>
        </div>
      </div>
    </>
  );
}

export default Products;
