import "./Nav.css";
import React from "react";
import { Link } from "react-router-dom";
// import AnchorLink from "react-anchor-link-smooth-scroll";
import logo from "./Images/logo.png";

function Nav() {
  return (
    <div className="topnav">
      {/* <Link className="logo"> */}
      <img src={logo} alt="logo" className="logo"></img>
      {/* </Link> */}
      <label for="hamburger">&#9776;</label>
      <input type="checkbox" id="hamburger" />
      <div className="menu">
        <Link to="/">Home</Link>
        <Link to="/AboutUs">About Us</Link>
        <div className="dropdown">
          {/* <button className="dropbtn"> */}
            <Link>Products</Link>
          {/* </button> */}
          <div className="dropdown-content">
            <Link to="/BE">Bucket Elevator</Link>
            <Link to="/SC">Screw Conveyor</Link>
            <Link to="/CC">Chain Conveyor</Link>
            <Link to="/BC">Belt Conveyor</Link>
            <Link to="/Slidegate">Slide Gate</Link>
            <Link to="/Divertor">Divertor</Link>
            <Link to="/TruckLoader">Truck Loader</Link>
            <Link to="/BagStacker">Bag Stacker</Link>
            <Link to="/PortableBeltConveyor">Portable Belt Conveyor</Link>
            <Link to="/ZtypeBucketConveyor">Z Type Bucket Conveyor</Link>
            <Link to="/TurnHead">Turn Head</Link>
            {/* <Link to="/">Spares</Link> */}
          </div>
        </div>
        {/* <Link to="/OurClients">Our Clients</Link> */}
        <Link to="/">Career</Link>
        <Link to="/CSR">CSR</Link>
        <Link to="/">Contact Us</Link>
        {/* <AnchorLink href="#CU">Contact Us</AnchorLink> */}
      </div>
    </div>
  );
}

export default Nav;
